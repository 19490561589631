<template>
  <article class="our-team" :class="{ mobile: isMobile }">
    <Hero heading="Meet the Electrify America Team" />
    <section>
      <div class="blurb">
        <h4>Get to know our people</h4>
        <p>
          At Electrify America, we’re proud of our diverse, talented team of experts who drive innovation in electric
          vehicle (EV) charging every day. Beyond our accomplished leadership team, we have a dedicated workforce
          committed to advancing electric mobility. Our culture is collaborative, forward-thinking, and inclusive, where
          every voice contributes to creating accessible, reliable charging solutions for our coast-to-coast network.
        </p>
        <p>Together, we’re building a trusted network that drivers and communities can depend on</p>
      </div>
    </section>
    <div v-for="(array, index) in ourTeamArray" :key="array.name">
      <div class="team-container" :class="{ 'has-bg': index % 2 == 0 }" data-aos="fade-up">
        <div class="team-photo">
          <img :src="require(`@/assets/images/OurTeam/${array.imageName}.png`)" width="250" />
        </div>
        <div class="team-description">
          <h2 class="p">{{ array.position }}</h2>
          <h3 class="headline2">{{ array.name }}</h3>
          <p>{{ array.description }}</p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import Hero from '@/components/Hero/Hero.vue';

export default {
  name: 'our-team',
  metaInfo: {
    title: 'Meet the Electrify America Team | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Electrify America’s leadership takes our commitment to investing in the U.S. electric vehicle public charging infrastructure seriously. Get to know our team.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/our-team/' }],
  },
  components: { Hero },
  data() {
    return {
      site: process.env.VUE_APP_SITE,
      ourTeamJson: require('@/assets/json/OurTeam.json'),
    };
  },
  computed: {
    getLang() {
      return this.$store.state.lang;
    },
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    ourTeamArray() {
      return this.ourTeamJson.language[0].enus;
    },
  },
};
</script>

<style scoped lang="scss">
.our-team {
  .team-container {
    display: flex;
    flex-direction: row-reverse;

    padding-left: 10%;
    padding-right: 10%;
    align-items: stretch;

    @media (max-width: 750px) {
      flex-direction: column;
    }
  }
  .has-bg {
    background-color: $c-secondary-background;
    flex-direction: row;

    @media (max-width: 750px) {
      flex-direction: column;
    }
  }

  .team-photo {
    text-align: center;
    align-content: center;
    padding-top: 40px;

    @media (min-width: 750px) {
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 0px;

      img {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
  .team-description {
    padding-top: 60px;
    padding-bottom: 40px;

    @media (min-width: 750px) {
      max-width: 65%;
    }
    .p {
      font-size: 1rem !important;
      margin-bottom: 0px;
    }

    h2 {
      font-size: 40px;
      line-height: 1.2;
      margin: -20px 0 20px !important;
    }
  }
}
.blurb {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 50px;

  max-width: 1328px;
  width: calc(100% - 48px);

  margin-left: auto;
  margin-right: auto;
}

::v-deep .hero__subheading {
  padding-bottom: 50px;
}
</style>
